<template>
    <BaseSection
        width="content-sm"
        :color-theme="colorTheme"
    >
        <template
            v-if="sectionTitle"
            #title
        >
            {{ sectionTitle }}
        </template>

        <template #default>
            <FormInviteAFriend
                v-if="authStore.loggedIn"
                v-bind="$props"
            />

            <BasePanel v-else>
                <template #title>
                    Eerst even inloggen
                </template>

                <template #default>
                    <p>
                        Om je vrienden te kunnen uitnodigen moet je eerst nog even inloggen.
                    </p>

                    <p>
                        <BaseButton
                            class="button--secondary button--centered"
                            @click.prevent="modalStore.open({ name: MODAL_LOGIN })"
                        >
                            Inloggen
                        </BaseButton>
                    </p>
                </template>
            </BasePanel>
        </template>
    </BaseSection>
</template>

<script setup lang="ts">
import type { FlexibleInviteAFriendFragment } from '~/@types/craft-schema';
import { useAuthStore } from '~/store/auth';
import { MODAL_LOGIN, useModalStore } from '~/store/modal';

defineProps<FlexibleInviteAFriendFragment>();

const modalStore = useModalStore();
const authStore = useAuthStore();
</script>
