<template>
    <div
        v-if="event"
        class="event-meta"
    >
        <div
            v-if="event.start_date"
            class="event-meta__item event-meta__date"
        >
            <span
                v-if="event.isCancelled"
                class="event-meta__cancelled"
            >
                Afgelast ·
            </span>

            <EventDate
                :start-date="event.start_date"
                :end-date="event.end_date || null"
            />
        </div>

        <h3
            v-if="title"
            :title="title"
            class="event-meta__item event-meta__title"
        >
            {{ title }}
            <span
                v-if="event.isPremium"
                class="event-meta__premium"
                title="Premium programma"
            >
                ⭑
            </span>
        </h3>

        <div
            v-if="artists && artists.length && !event.hideArtist"
            class="event-meta__item event-meta__artists"
        >
            <EventArtists :artists="artists" />
        </div>

        <div
            v-if="event.venue"
            class="event-meta__item event-meta__venue"
        >
            <EventVenue :venue="event.venue" />
        </div>

        <EventPriceParagraph
            v-if="event && showPrice"
            :event="event"
            :has-reservation="hasReservation"
            class="event-meta__item event-meta__price"
        />
    </div>
</template>

<script>
export default {
    props: {
        event: {
            type: Object,
            default: null
        },
        showPrice: {
            type: Boolean,
            default: false
        },
        hasReservation: {
            type: Boolean,
            default: false
        },
    },

    computed: {
        title() {
            if (!this.event) {
                return;
            }

            return this.event.title;
        },

        artists() {
            if (!this.event || !this.event.artists || !this.event.artists.length) {
                return;
            }

            return this.event.artists;
        },
    }
};
</script>

<style lang="less" src="./EventMeta.less"></style>
