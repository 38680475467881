<template>
    <BaseForm
        class="account-details"
        @submit="submitAccountDetails"
    >
        <template #title>Vul je gegevens in</template>

        <template #content>
            <FieldValidator
                v-if="showBarcodeField"
                v-slot="{ field, meta, errors, handleReset }"
                name="barcode"
            >
                <BaseFormField
                    :required="true"
                    :errors="errors"
                >
                    <template #label>Barcode</template>

                    <BaseInput
                        v-bind="field"
                        type="text"
                        :validator="meta"
                        @change="submissionErrorMessage = null"
                        @input="submissionErrorMessage = null"
                        @clear="handleReset"
                    />
                </BaseFormField>
            </FieldValidator>

            <FieldValidator
                v-slot="{ field, meta, errors, handleReset }"
                name="email"
            >
                <BaseFormField
                    :required="true"
                    :errors="errors"
                >
                    <template #label>E-mail</template>

                    <BaseInput
                        v-bind="field"
                        type="email"
                        :validator="meta"
                        @change="submissionErrorMessage = null"
                        @input="submissionErrorMessage = null"
                        @clear="handleReset"
                    />
                </BaseFormField>
            </FieldValidator>

            <p v-if="membershipStatus === MEMBERSHIP_ACTIVE">
                <template v-if="showBarcodeField">
                    Je hebt al een actieve WAP-Pas,
                    je kunt de giftcard activeren met een ander email adres.
                    Of stuur een mailtje naar info@wearepublic.nl
                </template>

                <template v-else>
                    Je hebt al een actieve We Are Public-pas.
                    Klik
                    <NuxtLink
                        to="/login"
                        class="account-details__login-link"
                    >
                        hier
                    </NuxtLink> om in te loggen.
                </template>
            </p>

            <p v-if="membershipStatus === MEMBERSHIP_INACTIVE && showBarcodeField">
                E-mail adres bestaat reeds, neem contact op met info@wearepublic.nl
            </p>

            <FieldValidator
                v-slot="{ field, meta, errors, handleReset }"
                name="password"
            >
                <BaseFormField
                    :required="true"
                    :errors="errors"
                >
                    <template #label>Kies een wachtwoord</template>
                    <BaseInput
                        v-bind="field"
                        type="password"
                        autocomplete="off"
                        :validator="meta"
                        @change="submissionErrorMessage = null"
                        @input="submissionErrorMessage = null"
                        @clear="handleReset"
                    />
                </BaseFormField>
            </FieldValidator>

            <FieldValidator
                v-slot="{ field, meta, errors, handleReset }"
                name="passwordConfirmation"
            >
                <BaseFormField
                    :errors="errors"
                >
                    <template #label>Herhaal wachtwoord</template>

                    <BaseInput
                        v-bind="field"
                        type="password"
                        autocomplete="off"
                        :validator="meta"
                        @change="submissionErrorMessage = null"
                        @input="submissionErrorMessage = null"
                        @clear="handleReset"
                    />

                    <template #info>
                        Je wachtwoord moet minimaal 8 tekens lang zijn.
                    </template>
                </BaseFormField>
            </FieldValidator>

            <FieldValidator
                v-slot="{ field, handleReset }"
                name="custom"
            >
                <BaseFormField v-if="customFieldEnabled">
                    <template #label>{{ customFieldLabel }}</template>

                    <BaseInput
                        v-bind="field"
                        type="text"
                        @clear="handleReset"
                    />

                    <template #info>{{ customFieldDescription }}</template>
                </BaseFormField>
            </FieldValidator>

            <BaseCheckbox
                v-model="consentGiven"
                :value="true"
                @change="submissionErrorMessage = null"
            >
                <!-- eslint-disable-next-line -->
                <span v-html="authorization" />
            </BaseCheckbox>

            <!-- eslint-disable vue/no-v-html -->
            <div
                v-if="!!submissionErrorMessage"
                class="form__error"
                v-html="submissionErrorMessage"
            />
            <!-- eslint-enable vue/no-v-html -->
        </template>

        <template #actions>
            <BaseButton
                :disabled="isSubmitting"
                type="submit"
                class="form__submit button--secondary form__button--max-width"
            >
                Volgende stap
            </BaseButton>
        </template>
    </BaseForm>
</template>

<script setup lang="ts">
import {
    GIFT as FORM_GIFT,
    REGULAR as FORM_REGULAR
} from '@/constants/signUpFormTypes.js';
import {
    ACTIVE as MEMBERSHIP_ACTIVE,
    INACTIVE as MEMBERSHIP_INACTIVE,
    UNKNOWN as MEMBERSHIP_UNKNWON,
} from '@/constants/userMembershipStatus.js';
import {
    STATUS as HTTP_STATUS
} from '@/constants/errorMessages.js';
import { useSignUpStore } from '~/store/signUp';
import { useEmailConfirmStore } from '~/store/emailConfirm';
import { useForm, Field as FieldValidator } from 'vee-validate';
import type { Maybe } from '~/@types/craft-schema';

const props = withDefaults(
    defineProps<{
        authorization?: Maybe<string>;
        customFieldEnabled?: Maybe<boolean>;
        customFieldLabel?: Maybe<string>;
        customFieldDescription?: Maybe<string>;
        formType?: Maybe<string>;
        campaign?: Maybe<string>;
    }>(),
    {
        formType: FORM_REGULAR,

        authorization: undefined,
        customFieldEnabled: undefined,
        customFieldLabel: undefined,
        customFieldDescription: undefined,
        campaign: undefined
    }
);

const emit = defineEmits(['submit']);

const isSubmitting = ref(false);
const membershipStatus = ref<string|null>(null);
const submissionErrorMessage = ref<string|null>(null);

const showBarcodeField = props.formType === FORM_GIFT;

interface AccountDetailsForm {
    barcode: string;
    email: string;
    password: string;
    passwordConfirmation: string;
    custom: string;
}

const { handleSubmit } = useForm<AccountDetailsForm>({
    validationSchema: {
        barcode: showBarcodeField ? 'required' : null,
        email: 'email',
        password: 'required|min:8',
        passwordConfirmation: 'required|min:8',
        custom: null
    }
});

const consentGiven = ref(false);

const ERROR_ONLY_NEW_MEMBERS = 'ONLY_NEW_MEMBERS' as const;
const ERROR_EMAIL_DOMAIN_NOT_ALLOWED = 'EMAIL_DOMAIN_NOT_ALLOWED' as const;


const checkBarcode = async(barcode: string) => {
    const { data, error } = await useFetchLaravel<{ success: boolean }>(
        '/v2.0/gift/check',
        {
            method: 'POST',
            body: {
                barcode: barcode
            }
        }
    );

    if (error.value?.statusCode === 400) {
        submissionErrorMessage.value = 'Barcode incorrect';
    } else if (error.value) {
        submissionErrorMessage.value = HTTP_STATUS['500'];
    }

    return data.value?.success ? true : false;
};

const checkEmail = async(email: string) => {
    const { data, error } = await useFetchLaravel<{
        success: boolean,
        membershipStatus: typeof MEMBERSHIP_ACTIVE | typeof MEMBERSHIP_INACTIVE | typeof MEMBERSHIP_UNKNWON,
        error: typeof ERROR_ONLY_NEW_MEMBERS | typeof ERROR_EMAIL_DOMAIN_NOT_ALLOWED
    }>('/v2.0/user/email-confirmation/send', {
        method: 'POST',
        body: {
            email: email,
            campaign_id: props.campaign || null
        }
    });

    if (error.value) {
        submissionErrorMessage.value = 'Er ging iets mis…';
    } else if (data.value?.success === false) {
        submissionErrorMessage.value = 'E-mailadres is al in gebruik…';
    }

    isSubmitting.value = false;

    return data.value;
};

const submitAccountDetails = handleSubmit(async(formData) => {
    submissionErrorMessage.value = null;

    if (formData.password !== formData.passwordConfirmation) {
        submissionErrorMessage.value = 'De wachtwoorden komen niet overeen';
        return;
    }

    if (!consentGiven.value) {
        submissionErrorMessage.value = 'Accepteer de voorwaarden om door te gaan';
        return;
    }

    isSubmitting.value = true;

    if (showBarcodeField) {
        const barcodeValid = await checkBarcode(formData.barcode);

        if (barcodeValid !== true) {
            isSubmitting.value = false;
            return;
        }
    }

    const emailCheckResponse = await checkEmail(formData.email);

    if (emailCheckResponse?.error && emailCheckResponse.error === ERROR_ONLY_NEW_MEMBERS) {
        submissionErrorMessage.value = 'Je hebt al een keer gebruik gemaakt van deze aanbieding. ' +
            'Wil je je oude lidmaatschap heractiveren? ' +
            '<a href="/heractiveer"><i><u>Klik hier</u></i></a>';
        return;
    }

    if (emailCheckResponse?.error && emailCheckResponse.error === ERROR_EMAIL_DOMAIN_NOT_ALLOWED) {
        submissionErrorMessage.value = 'Met dit e-mailadres kun je je niet aanmelden via deze pagina.';
        return;
    }

    if (
        emailCheckResponse?.membershipStatus === MEMBERSHIP_ACTIVE ||
        emailCheckResponse?.membershipStatus === MEMBERSHIP_INACTIVE ||
        emailCheckResponse?.membershipStatus === MEMBERSHIP_UNKNWON
    ) {
        membershipStatus.value = emailCheckResponse.membershipStatus as string;

        useSignUpStore().setMembershipStatus(membershipStatus.value);
    }

    if (emailCheckResponse?.success === true) {
        useEmailConfirmStore().setEmail(formData.email);

        isSubmitting.value = false;

        emit('submit', {
            barcode: formData.barcode,
            password: formData.password,
            password_confirmation: formData.passwordConfirmation,
            email: formData.email,
            consentGiven: consentGiven.value,
            custom_field: formData.custom
        });
    } else if (
        emailCheckResponse?.success === false &&
        emailCheckResponse?.membershipStatus === MEMBERSHIP_ACTIVE
    ) {
        isSubmitting.value = false;
        membershipStatus.value = MEMBERSHIP_ACTIVE;
    }
});
</script>

<style lang="less" src="./FormAccountDetails.less" />
