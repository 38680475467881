import type { UseFetchOptions } from 'nuxt/app';
import { useAuthStore } from '~/store/auth';

function useFetchLaravel<T>(path: string, fetchOptions: UseFetchOptions<T> = {}) {
    const app = useNuxtApp();

    const { laravelApiToken, laravelApiBaseURL } = useRuntimeConfig().public;

    const authStore = useAuthStore();

    const jwtToken = authStore.jwtToken;

    const { jwtTokenCookie } = useJwtCookies();

    const token = jwtToken ?? jwtTokenCookie.value;

    const parsedFetchOptions: UseFetchOptions<T> = {
        method: 'GET',
        ...fetchOptions,
        headers: {
            'x-api-key': laravelApiToken,
            'Accept': 'application/json',
            ... token
                ? { Authorization: `Bearer ${token}` }
                : {}
        }
    };

    return useFetch(
        laravelApiBaseURL + path,
        {
            ...parsedFetchOptions,
            $fetch: app.$customFetch as typeof globalThis.$fetch,
        }
    );
}

export default useFetchLaravel;
