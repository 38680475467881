import {
    JWT_COOKIE_CONFIG,
    JWT_COOKIE_KEY,
    JWT_COOKIE_NEEDS_REFRESH_AT_KEY
} from '~/constants/cookies';

const ONE_HOUR = 3_540_000;

function useJwtCookies() {
    refreshCookie(JWT_COOKIE_KEY);
    refreshCookie(JWT_COOKIE_NEEDS_REFRESH_AT_KEY);

    const jwtTokenCookie = useCookie(JWT_COOKIE_KEY, JWT_COOKIE_CONFIG);

    const jwtTokenCookieNeedsRefreshAt = useCookie(
        JWT_COOKIE_NEEDS_REFRESH_AT_KEY,
        JWT_COOKIE_CONFIG
    );

    const setCookies = (token: string) => {
        jwtTokenCookie.value = token;
        jwtTokenCookieNeedsRefreshAt.value = ( Date.now() + ONE_HOUR ).toString(10);

        refreshCookie(JWT_COOKIE_KEY);
        refreshCookie(JWT_COOKIE_NEEDS_REFRESH_AT_KEY);
    };

    const unsetCookies = () => {
        jwtTokenCookie.value = null;
        jwtTokenCookieNeedsRefreshAt.value = null;

        refreshCookie(JWT_COOKIE_KEY);
        refreshCookie(JWT_COOKIE_NEEDS_REFRESH_AT_KEY);
    };

    return {
        jwtTokenCookie,
        jwtTokenCookieNeedsRefreshAt,
        setCookies,
        unsetCookies
    };
}

export default useJwtCookies;
