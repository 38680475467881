<template>
    <slot name="before" />

    <template v-for="(section, index) in filteredSections">
        <FlexibleSectionEventBlock
            v-if="section.__typename === 'flexibleSections_eventBlock_BlockType'"
            :key="`events-section_${section.id}`"
            :lazy="index > 3"
            v-bind="section"
        />

        <FlexibleSectionCollectionsBlock
            v-else-if="section.__typename === 'flexibleSections_collectionsBlock_BlockType'"
            :key="`collection-section_${section.id}-collection`"
            :lazy="index > 3"
            v-bind="section"
        />

        <component
            :is="componentMap[section.__typename]"
            v-else
            :key="`content-section_${section.id}`"
            v-bind="section"
        />
    </template>

    <slot name="after" />
</template>

<script setup lang="ts">
import { resolveComponent } from 'vue';
import type { FlexibleSections_NeoField, FlexibleSectionsFragment, Maybe } from '~/@types/craft-schema';
import { RENDERCONDITION_LOGGED_IN, RENDERCONDITION_GUESTS } from '~/constants/renderConditions';
import { useAuthStore } from '~/store/auth';

const props = defineProps<{
    sections: Maybe<FlexibleSections_NeoField>[]
}>();

const authStore = useAuthStore();

const componentMap = {
    flexibleSections_richText_BlockType: resolveComponent('FlexibleSectionRichText'),
    flexibleSections_image_BlockType: resolveComponent('FlexibleSectionTheImage'),
    flexibleSections_images_BlockType: resolveComponent('FlexibleSectionTheImages'),
    flexibleSections_video_BlockType: resolveComponent('FlexibleSectionTheVideo'),
    flexibleSections_quote_BlockType: resolveComponent('FlexibleSectionTheQuote'),
    flexibleSections_callToAction_BlockType: resolveComponent('FlexibleSectionCallToAction'),
    flexibleSections_textImage_BlockType: resolveComponent('FlexibleSectionTextImage'),
    flexibleSections_textText_BlockType: resolveComponent('FlexibleSectionTextText'),
    flexibleSections_textVideo_BlockType: resolveComponent('FlexibleSectionTextVideo'),
    flexibleSections_signup_BlockType: resolveComponent('FlexibleSectionSignupForm'),
    flexibleSections_inviteAFriend_BlockType: resolveComponent('FlexibleSectionInviteAFriend'),
    flexibleSections_orderGift_BlockType: resolveComponent('FlexibleSectionOrderGift'),
    flexibleSections_eventBlock_BlockType: resolveComponent('FlexibleSectionEventBlock'),
    flexibleSections_collectionsBlock_BlockType: resolveComponent('FlexibleSectionCollectionsBlock'),
    flexibleSections_featuredCollection_BlockType: resolveComponent('FlexibleSectionFeaturedCollection')
} as const;

const shouldRender = (section: FlexibleSections_NeoField) => {
    if (!section) {
        return false;
    }

    if (!section.__typename || !componentMap[section.__typename]) {
        return false;
    }

    if (!section || !section.renderConditions || !section.renderConditions.length) {
        return false;
    }

    if (!section.renderConditions.includes(RENDERCONDITION_LOGGED_IN) && authStore.loggedIn) {
        return false;
    }

    if (!section.renderConditions.includes(RENDERCONDITION_GUESTS) && !authStore.loggedIn) {
        return false;
    }

    return true;
};

const filteredSections = computed(() => {
    return props.sections.filter(section => {
        if (section && shouldRender(section)) {
            return true;
        }

        return false;
    }) as FlexibleSectionsFragment[];
});
</script>
