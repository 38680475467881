import revive_payload_client_oJixKuLC9Q from "/builds/WeArePublic/wap-app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.9_eslint@8.57.1_ioredis@5.4.1_less@4.2.0_m_qxytjteevj6mn7keqtobcgmeoe/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_L0qpa3VpFk from "/builds/WeArePublic/wap-app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.9_eslint@8.57.1_ioredis@5.4.1_less@4.2.0_m_qxytjteevj6mn7keqtobcgmeoe/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_6eRMoYReXn from "/builds/WeArePublic/wap-app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.9_eslint@8.57.1_ioredis@5.4.1_less@4.2.0_m_qxytjteevj6mn7keqtobcgmeoe/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_6AXbFiW8P5 from "/builds/WeArePublic/wap-app/node_modules/.pnpm/nuxt-site-config@2.2.18_magicast@0.3.5_rollup@4.24.0_vite@5.4.10_@types+node@22.7.9_less@4.2._t5xnqgukgdznczbwbks4mdnvwe/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_m2mMhpwtZv from "/builds/WeArePublic/wap-app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.9_eslint@8.57.1_ioredis@5.4.1_less@4.2.0_m_qxytjteevj6mn7keqtobcgmeoe/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_c44lWFfGyl from "/builds/WeArePublic/wap-app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.9_eslint@8.57.1_ioredis@5.4.1_less@4.2.0_m_qxytjteevj6mn7keqtobcgmeoe/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_oxEYVwRi0K from "/builds/WeArePublic/wap-app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.9_eslint@8.57.1_ioredis@5.4.1_less@4.2.0_m_qxytjteevj6mn7keqtobcgmeoe/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_rkwtoW1fGu from "/builds/WeArePublic/wap-app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.9_eslint@8.57.1_ioredis@5.4.1_less@4.2.0_m_qxytjteevj6mn7keqtobcgmeoe/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_EzIQGIe80m from "/builds/WeArePublic/wap-app/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.0_typescript@5.6.3_vue@3.5.12_typescript@5.6.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/builds/WeArePublic/wap-app/.nuxt/components.plugin.mjs";
import prefetch_client_NG4gy8AJQb from "/builds/WeArePublic/wap-app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.9_eslint@8.57.1_ioredis@5.4.1_less@4.2.0_m_qxytjteevj6mn7keqtobcgmeoe/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/builds/WeArePublic/wap-app/.nuxt/pwa-icons-plugin.ts";
import pwa_client_p8H431rcOH from "/builds/WeArePublic/wap-app/node_modules/.pnpm/@vite-pwa+nuxt@0.10.5_@vite-pwa+assets-generator@0.2.6_magicast@0.3.5_rollup@4.24.0_vite@5.4._biqbwzl7afh7kko5chk5aeclsu/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import plugin_gL4tD6tD5T from "/builds/WeArePublic/wap-app/node_modules/.pnpm/@digitalnatives+graphql-client@4.1.1_graphql@16.9.0_magicast@0.3.5_nuxt@3.13.2_@parcel+watche_wbazcpcd7v7oxuwzzszh6oqjq4/node_modules/@digitalnatives/graphql-client/dist/runtime/plugin.mjs";
import plugin_iSeiHH8zLX from "/builds/WeArePublic/wap-app/node_modules/.pnpm/nuxt-bugsnag@8.0.0_@bugsnag+core@8.1.1_magicast@0.3.5_rollup@4.24.0_vue-router@4.4.5_vue@3.5.12_typescript@5.6.3__/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.js";
import nuxt_plugin_EDVYz58coz from "/builds/WeArePublic/wap-app/node_modules/.pnpm/nuxt-delay-hydration@1.3.8_magicast@0.3.5_rollup@4.24.0/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import dates_byd27p6IKa from "/builds/WeArePublic/wap-app/plugins/dates.js";
import globalData_ThkhP3KCAA from "/builds/WeArePublic/wap-app/plugins/globalData.ts";
import gtm_cXsUZobmSF from "/builds/WeArePublic/wap-app/plugins/gtm.ts";
import in_view_CFLen2qDdE from "/builds/WeArePublic/wap-app/plugins/in-view.js";
import v_clipboard_oVMg8deQnQ from "/builds/WeArePublic/wap-app/plugins/v-clipboard.js";
import v_dragscroll_JehTUROx3N from "/builds/WeArePublic/wap-app/plugins/v-dragscroll.js";
import vee_validate_KcKlKmvCrJ from "/builds/WeArePublic/wap-app/plugins/vee-validate.ts";
export default [
  revive_payload_client_oJixKuLC9Q,
  unhead_L0qpa3VpFk,
  router_6eRMoYReXn,
  _0_siteConfig_6AXbFiW8P5,
  payload_client_m2mMhpwtZv,
  navigation_repaint_client_c44lWFfGyl,
  check_outdated_build_client_oxEYVwRi0K,
  chunk_reload_client_rkwtoW1fGu,
  plugin_vue3_EzIQGIe80m,
  components_plugin_KR1HBZs4kY,
  prefetch_client_NG4gy8AJQb,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_p8H431rcOH,
  plugin_gL4tD6tD5T,
  plugin_iSeiHH8zLX,
  nuxt_plugin_EDVYz58coz,
  dates_byd27p6IKa,
  globalData_ThkhP3KCAA,
  gtm_cXsUZobmSF,
  in_view_CFLen2qDdE,
  v_clipboard_oVMg8deQnQ,
  v_dragscroll_JehTUROx3N,
  vee_validate_KcKlKmvCrJ
]