<template>
    <StateSwitch :state="firstRequestStatus">
        <template #default>
            <slot
                :events="events"
                :load-more="loadMore"
                :total-results="totalResults"
                :last-page-number="lastPageNumber"
                :current-page-number="currentPageNumber"
            />
        </template>

        <template #loading>
            <slot name="loading" />
        </template>

        <template #error>
            <slot name="error">
                <NoResults class="no-results--centered">
                    Er ging iets fout tijdens het laden van de programma's.
                </NoResults>
            </slot>
        </template>
    </StateSwitch>
</template>

<script setup lang="ts">
import type { AsyncDataRequestStatus } from '#app';
import type { EventDate } from '~/@types/data';
import { useEventBundle, type ComponentProps } from '~/composables/useEventBundle';

const firstRequestStatus = ref<AsyncDataRequestStatus>('pending');

const props = withDefaults(
    defineProps<ComponentProps>(),
    {
        lazy: true
    }
);

const emit = defineEmits(['show', 'hide']);

const {
    totalResults,
    currentPageNumber,
    lastPageNumber,
    filterQuery
} = useEventBundle(props);

const events = ref<EventDate[]|null>(null);

const fetchEventsParams = ref({
    ...filterQuery.value,
    page: currentPageNumber.value.toString()
});

const { data, status } = await useFetchLaravel<{
    events: EventDate[],
    meta: {
        total: number,
        lastPage: number
    }
}>(
    '/v2.0/events',
    {
        query: fetchEventsParams,
        lazy: !props.lazy,
    }
);

watch(filterQuery, () => {
    currentPageNumber.value = 1;

    fetchEventsParams.value = {
        ...filterQuery.value,
        page: currentPageNumber.value.toString()
    };
});

const loadMore = async() => {
    currentPageNumber.value += 1;

    fetchEventsParams.value = {
        ...filterQuery.value,
        page: currentPageNumber.value.toString()
    };
};

watch(data, () => {
    if (status.value !== 'success' || !data.value) {
        return;
    } else if (status.value === 'success') {
        firstRequestStatus.value = status.value;
    }

    if (currentPageNumber.value === 1) {
        events.value = data.value.events as EventDate[];
        totalResults.value = data.value.meta.total as number;
        lastPageNumber.value = data.value.meta.lastPage as number;

        if (totalResults.value === 0) {
            emit('hide');
        } else {
            emit('show');
        }
    } else if (currentPageNumber.value > 1) {
        events.value = [
            ...events.value as EventDate[],
            ...data.value.events
        ];
    }
}, {
    immediate: true
});
</script>
