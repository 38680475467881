import type { User } from '~/@types/data';
import { useUserStore } from '~/store/user';

async function useGetAndSetMember() {
    const app = useNuxtApp();

    const response = await useFetchLaravel<User>(
        '/v2.0/user/me',
        {
            method: 'GET'
        }
    );

    app.runWithContext(async() => {
        if (response.error.value?.statusCode === 401) {
            const { status } = await useRefreshJwtToken();

            if (status.value === 'success') {
                response.refresh();
            } else {

                const { jwtTokenCookie } = useJwtCookies();

                jwtTokenCookie.value = null;
            }
        }

        watch(response.status, () => {
            if (response.status.value === 'success' && response.data.value) {
                const userStore = useUserStore();

                userStore.setUser(response.data.value);
            }
        }, { immediate: true });
    });

    return response;
}

export default useGetAndSetMember;
