import type { Ether_SeoData, FairUsePolicy_GlobalSet, Footer_GlobalSet, MainNavigation_GlobalSet, Membership_GlobalSet, Seo_GlobalSet, WaitingList_GlobalSet } from '~/@types/craft-schema';
import GlobalsQuery from '~/graphql/queries/Globals.graphql';
import { useEventsStore } from '~/store/events';
import { useGlobalStore } from '~/store/global';
import { useNavigationStore } from '~/store/navigation';

function logMissingGlobalSet(globalSetName: string) {
    if (import.meta.server) {
        // eslint-disable-next-line no-console
        console.warn(`Coulnd't retrieve (${globalSetName})`);
    }
}

export default defineNuxtPlugin(async() => {
    const globalStore = useGlobalStore();

    if (!globalStore.fetched) {
        /**
         * Craft data
         */
        const { data: craftData, error: craftError } = await useAsyncQuery<{
            seo: Seo_GlobalSet;
            membership: Membership_GlobalSet;
            mainNavigation: MainNavigation_GlobalSet;
            footer: Footer_GlobalSet;
            fairUsePolicy: FairUsePolicy_GlobalSet;
            waitingList: WaitingList_GlobalSet;
        }>({
            query: GlobalsQuery
        });

        if (craftError.value) {
            if (process.env.APP_ENV === 'development') {
                console.error(craftError.value);
            }
            console.error('Coulnd\'t retrieve craft global data in plugins/globalData.ts');
        }

        if (!craftData.value) {
            return;
        }

        const navigationStore = useNavigationStore();

        const {
            seo,
            membership,
            mainNavigation,
            footer,
            fairUsePolicy,
            waitingList
        } = craftData.value;

        globalStore.fetched = true;
    
        if (!seo?.seo) {
            logMissingGlobalSet('Ether_SeoData');
        } else {
            globalStore.seo = seo.seo as Ether_SeoData;
        }

        if (!membership) {
            logMissingGlobalSet('Membership_GlobalSet');
        } else {
            globalStore.setMembershipTextData(membership as Membership_GlobalSet);
        }

        if (!mainNavigation) {
            logMissingGlobalSet('MainNavigation_GlobalSet');
        } else {
            navigationStore.setMainNavigation(mainNavigation as MainNavigation_GlobalSet);
        }

        if (!footer) {
            logMissingGlobalSet('Footer_GlobalSet');
        } else {
            navigationStore.setFooter(footer as Footer_GlobalSet);
        }

        if (!fairUsePolicy) {
            logMissingGlobalSet('FairUsePolicy_GlobalSet');
        } else {
            useEventsStore().setFairUsePolicy(fairUsePolicy);
        }

        if (!waitingList) {
            logMissingGlobalSet('WaitingList_GlobalSet');
        } else {
            useEventsStore().setWaitingListInfo(waitingList);
        }

        /**
         * Laravel data
         */
        const { data: laravelData } = await useFetchLaravel<{ max_reservations: number }>('/v2.0/global-data');

        if (!laravelData.value) {
            console.error('Coulnd\'t retrieve laravel global data in plugins/globalData.ts');
        }

        if (laravelData.value) {
            globalStore.setMaxReservations(laravelData.value.max_reservations);
        }
    }
});
