<template>
    <p>
        <span v-if="hasReservation && paidPrice !== undefined">
            Jouw We Are Public-prijs:
            {{ paidPrice ? ('€' + parseTicketPrice(paidPrice)) : 'gratis' }}
            <br>
        </span>

        <span v-else-if="ticketPricing.isFree && !ticketPricing.needsPayment">
            Tickets zijn gratis met de We Are Public-pas
            <br>
        </span>

        <span v-else-if="ticketPricing.needsPayment && ticketPricing.parsedPrice">
            We Are Public-prijs: €{{ ticketPricing.parsedPrice }}
            <br>
        </span>

        <span v-if="ticketPricing.priceRegular">
            Reguliere prijs: €{{ ticketPricing.parsedPriceRegular }}
            <br>
        </span>
    </p>
</template>

<script setup lang="ts">
import type { EventDate } from '~/@types/data';
import { useEventsStore } from '~/store/events';

const props = defineProps<{
    event: EventDate;
    hasReservation: boolean;
}>();

const eventsStore = useEventsStore();

const ticketPricing = computed(() => eventsStore.ticketPricing);
const paidPrice = ref<number|null|undefined>(undefined);

const fetchPaidPrice = async() => {
    if (!props.hasReservation) {
        return;
    }

    const { data } = await useFetchLaravel<{
        paidPrice: number;
        type: string;
    }>(`/v2.0/user/me/reservations/${props.event.id}`, {
        method: 'GET',
    });

    if (data?.value) {
        paidPrice.value = data.value?.paidPrice;
    }
};

watch(() => props.hasReservation, (updated) => {
    if (updated) {
        fetchPaidPrice();
    }
}, {
    immediate: true,
});
</script>
