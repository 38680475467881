function useJwtTokenNeedsRefresh() {
    const { jwtTokenCookieNeedsRefreshAt } = useJwtCookies();

    return computed(() => {
        if (typeof jwtTokenCookieNeedsRefreshAt.value === 'string') {
            return parseInt(jwtTokenCookieNeedsRefreshAt.value, 10) < Date.now();
        }
    
        return false;
    });
}

export default useJwtTokenNeedsRefresh;
