<template>
    <EventBundleSection
        :presentation="presentation"
        :event-bundle-query="eventBundleQuery"
        :section-width="sectionWidth"
    >
        <template
            v-if="title"
            #title
        >
            {{ title }}
        </template>

        <template
            v-if="callToAction && callToAction.url"
            #readMore
        >
            <NavLink
                :type="mapType(callToAction.type)"
                :url="mapURI(callToAction)"
                :blank="isExternal(callToAction)"
            >
                {{ callToAction.text || 'Bekijk alles' }}
            </NavLink>
        </template>
    </EventBundleSection>
</template>

<script setup lang="ts">
import type { EventBundleFragment, FlexibleEventBlockFragment } from '~/@types/craft-schema';
import { presentationTypes, type PresentationType } from '~/composables/useEventBundle';

interface Props extends FlexibleEventBlockFragment {
    lazy?: boolean;
}

const props = withDefaults(
    defineProps<Props>(),
    {
        lazy: true
    }
);

const { mapType, mapURI, isExternal } = useNavigation();

const presentation = computed<PresentationType>(() =>
    props.eventBlockPresentationType
    && presentationTypes.includes(props.eventBlockPresentationType as any)
        ? props.eventBlockPresentationType as PresentationType
        : 'slider'
);

const sectionWidth = computed(() => {
    if (props.eventBlockPresentationType === 'slider') {
        return 'bleed';
    }

    return 'page';
});

const eventBundleQuery = props.eventBundleEntry &&
    props.eventBundleEntry.length > 0 &&
    props.eventBundleEntry[0]?.__typename === 'eventBundles_default_Entry'
    ? props.eventBundleEntry[0].eventBundle[0] as EventBundleFragment
    : props.eventBundle[0] as EventBundleFragment;
</script>
