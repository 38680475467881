import { defineStore } from 'pinia';
import type { CityFilterOptions, RegionFilterOptions } from '~/@types/data';
import type { Page_ProgramOverview_Entry } from '~/@types/craft-schema';

export const useEventFiltersStore = defineStore('eventFilters', {
    state: () => ({
        filtersVisible: false,
        filterOptions: null as null | {
            region: RegionFilterOptions;
            city: CityFilterOptions;
        },
        mainProgramEntry: null as null | Page_ProgramOverview_Entry
    }),

    getters: {
        cityOptions(): CityFilterOptions {
            if (!this.filterOptions || !this.filterOptions.city) {
                return [];
            }

            const optionsClone = [...this.filterOptions.city];

            // Sort alphabetically
            optionsClone.sort((a, b) => {
                const labelA = a.label.toUpperCase();
                const labelB = b.label.toUpperCase();

                return (labelA < labelB) ? -1 : (labelA > labelB) ? 1 : 0;
            });

            return optionsClone;
        },

        regionOptions(): RegionFilterOptions  {
            if (!this.filterOptions || !this.filterOptions.region) {
                return [];
            }

            return this.filterOptions.region;
        }
    }
});
