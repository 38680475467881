<template>
    <NuxtLayout>
        <NuxtPage />
    </NuxtLayout>
</template>

<script setup lang="ts">
const { jwtTokenCookie } = useJwtCookies();
const jwtTokenNeedsRefresh = useJwtTokenNeedsRefresh();

if (jwtTokenCookie.value && jwtTokenNeedsRefresh.value) {
    const { status } = await useRefreshJwtToken();

    if (status.value === 'success') {
        await useGetAndSetMember();
    }
} else if (jwtTokenCookie.value) {
    await useGetAndSetMember();
}
</script>
