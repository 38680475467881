import type { EventDate } from '~/@types/data';

/**
 * The current eslint version doesn't like enums
 */

/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
export enum ReservationType {
    FREE = 'FREE',
    DISCOUNT = 'DISCOUNT',
    EXTRA_CAPACITY = 'EXTRA_CAPACITY',
    PREMIUM = 'PREMIUM',
}
/* eslint-enable */

export type TicketPricing = {
    priceRegular: number | null,
    parsedPriceRegular: string | null,
    price: number | null,
    parsedPrice: string | null,
    isFree: boolean,
    moneySaved: number | null,
    parsedMoneySaved: string | null,
    payPremium: boolean,
    needsPayment: boolean
    type: ReservationType
};

export const emptyTicketPricing: TicketPricing = {
    priceRegular: null,
    parsedPriceRegular: null,
    price: null,
    parsedPrice: null,
    isFree: true,
    moneySaved: null,
    parsedMoneySaved: null,
    payPremium: false,
    needsPayment: false,
    type: ReservationType.FREE,
};

export function parseTicketPrice(price?: number|string|null) {
    if (!price) {
        return '0';
    }

    if (typeof price === 'number') {
        price = price.toString();
    }

    // when string ends with ".00" from string
    if (price.search(/\.00/) > -1) {
        return price.replace(/\.00/, '');
    }
    // when string has no decimals return as is
    else if (price.search(/\./) < 0) {
        return price;
    }
    // when string has only 1 decimal at 0 at the end
    else if (price.search(/\.\d{1}$/) > -1) {
        return price.replace(/\./, ',') + '0';
    }

    // when price is null return 0
    if (typeof price !== 'string') {
        return '0';
    }

    return price.replace(/\./, ',');
}

export function calculateTicketPricing(
    event: EventDate,
    loggedIn: boolean,
    canMakeFreePremiumReservation: boolean,

): TicketPricing {
    const isFree = event.freeForMembers,
        payPremium = event.isPremium && !canMakeFreePremiumReservation,
        needsPayment = !isFree
            || payPremium
            || (!event.canMakeFreeReservation && event.hasExtraCapacity),
        priceRegular = event.ticketPriceRegular,
        parsedPriceRegular = parseTicketPrice(priceRegular);

    let price = null,
        parsedPrice = null,
        moneySaved = null,
        parsedMoneySaved = null,
        type = ReservationType.FREE;

    if (
        payPremium
        && event.ticketPricePremium
        && loggedIn
    ) {
        price = event.ticketPricePremium;
        type = ReservationType.PREMIUM;
    } else if (
        needsPayment
        && !event.canMakeFreeReservation
        && event.ticketPriceExtraCapacity
        && event.hasExtraCapacity
    ) {
        price = event.ticketPriceExtraCapacity;
        type = ReservationType.EXTRA_CAPACITY;
    } else if (
        !event.freeForMembers
        && event.ticketPriceMembers
    ) {
        price = event.ticketPriceMembers;
        type = ReservationType.DISCOUNT;
    }

    if (price) {
        parsedPrice = parseTicketPrice(price);
    }

    moneySaved = Math.round(
        (
            parseFloat(event.ticketPriceRegular?.toString(10) || '0') -
            parseFloat(price?.toString() || '0') +
            Number.EPSILON
        ) * 100
    ) / 100;

    if (moneySaved) {
        parsedMoneySaved = parseTicketPrice(moneySaved);
    }

    return {
        isFree,
        payPremium,
        needsPayment,
        priceRegular,
        parsedPriceRegular,
        price,
        parsedPrice,
        moneySaved,
        parsedMoneySaved,
        type
    };
}
