import type { User } from '~/@types/data';
import { defineStore } from 'pinia';

export const useUserStore = defineStore('user', {
    state: () => {
        return {
            user: null as null | User,
        };
    },

    getters: {
        reservations(): number[] {
            return this.user?.reservations || [];
        },

        canMakeReservation(): boolean {
            return this.user?.canMakeReservation ? true : false;
        },

        canMakeFreePremiumReservation(): boolean {
            return this.user?.canMakeFreePremiumReservation ? true : false;
        }
    },

    actions: {
        setUser(payload: User) {
            this.user = payload;
        },

        hasReservation(id: number) {
            const reservations = this.user?.reservations || [];

            return reservations.includes(id);
        },

        isInWaitingList(id: number) {
            const waitingList = this.user?.waitingLists || [];

            return waitingList.includes(id);
        },

        hasFavorite(id: number) {
            const favorites = this.user?.savedProgrammes || [];

            return favorites.includes(id);
        }
    }
});
