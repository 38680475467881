<template>
    <NuxtLink
        v-if="type === NUXT_LINK && url"
        :to="url"
        v-bind="$attrs"
        @click="$emit('click')"
    >
        <slot />
    </NuxtLink>

    <a
        v-else-if="type === LINK"
        :href="url"
        :target="blank ? '_blank' : null"
        rel="noopener noreferrer"
        v-bind="$attrs"
        @click="$emit('click')"
    >
        <slot />
    </a>
</template>

<script>
import { LINK, NUXT_LINK } from '~/composables/useNavigation';

export default {
    props: {
        type: {
            type: String,
            validator: value => [LINK, NUXT_LINK].includes(value),
            default: NUXT_LINK
        },
        url: {
            type: [String, Object],
            required: true
        },
        blank: {
            type: Boolean,
            default: false
        }
    },

    emits: ['click'],

    setup() {
        return {
            LINK,
            NUXT_LINK
        };
    }
};
</script>
