import { useAuthStore } from '~/store/auth';

async function useRefreshJwtToken() {
    const { setCookies } = useJwtCookies();

    const authStore = useAuthStore();

    const response = await useFetchLaravel<{ token: string }>(
        '/v1.0/auth/jwt',
        {
            method: 'PUT'
        }
    );

    watch(response.status, () => {
        if (response.status.value === 'success' && response.data.value?.token) {
            setCookies(response.data.value.token);

            authStore.jwtToken = response.data.value.token;
        }
    }, { immediate: true });

    return response;
}

export default useRefreshJwtToken;
